@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap");
@tailwind base;
@tailwind components;

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}

.ant-input-number input::-webkit-outer-spin-button,
.ant-input-number input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ant-input-number input {
  -moz-appearance: textfield; /* Firefox */
}

a {
  color: #4048f1;
  text-decoration: none;
}

/* .row-link {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 3rem;
  outline: black;
} */

@keyframes grow {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.animate-grow {
  animation: grow 0.5s ease-in-out forwards;
}

.ant-input-number {
  position: relative;
}

.ant-input-number-handler-wrap {
  display: none;
}

@tailwind utilities;
